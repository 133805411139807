import React from "react";
import {Link} from "wouter-preact";
import LinkItem from "../LinkItem.jsx";

import "./footerMobile.css";

import logo from "../../../svg/new/_logo_white.svg";

import visa from "../../../svg/new/_visa.svg";
import mastercard from "../../../svg/new/_mastercard.svg";
import opay from "../../../svg/new/_opay.svg";
import verve from "../../../svg/new/_verve.svg";
import nib from "../../../svg/new/_nib.svg";
import socialLinks from '../social.js'
import i18n from "../../../../i18n/i18n.js";

const FooterMobileView = props => {
    return (
        <footer>
            <div className='m-footer-content'>
                <div className="m-footer-logo">
                    <Link to='/' className='logo'>
                        <img loading="lazy" src={logo} alt='Logo'/>
                    </Link>
                </div>
                <div className='m-footer-content-links-container'>
                    <div className='m-footer-content-link-title'>
                        {i18n.get("footer.title.navigation")}
                    </div>
                    <div className='m-footer-content-links'>
                        {props.mainLinks.map((link, index) => {
                            return (
                                <LinkItem
                                    key={index}
                                    url={link.url}
                                    text={link.text}
                                />
                            );
                        })}
                    </div>
                </div>
                <div className='m-footer-content-links-container'>
                    <div className='m-footer-content-link-title'>
                        {i18n.get("text.information.for.user.1")}
                    </div>
                    <div className='m-footer-content-links'>
                        {props.informationLinks.map((link, index) => {
                            return (
                                <LinkItem
                                    key={index}
                                    url={link.url}
                                    text={link.text}
                                />
                            );
                        })}
                    </div>
                </div>
                <div className='m-footer-content-payment-container'>
                    <div className='m-footer-content-link-title'>
                        {i18n.get("text.follow.us")}
                    </div>
                    <div className='m-footer-content-socials'>
                        {socialLinks.map(({ icon, link }, index) => (
                            <a key={link} target="_blank" href={link} rel="noreferrer">
                                <div className='footer-content-social' key={index}>
                                    <img loading="lazy" src={icon} alt="Logo"/>
                                </div>
                            </a>
                        ))}
                    </div>
                    <div className='m-footer-content-link-title'>
                        {i18n.get("footer.title.we.accept")}
                    </div>
                    <div className='m-footer-content-payments'>
                        {[visa, mastercard, opay, verve, nib].map((x, index) => (
                            <div key={index} className='m-footer-content-payment'>
                                <img loading="lazy" src={x} alt=''/>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='m-footer-content-support'>
                    <div className='m-footer-content-support-title'>
                        {i18n.get("text.support.number")}
                    </div>
                    <div className='m-footer-content-support-number'>
                        <a href='https://wa.me/2349135370349'>+234 913 537 0349</a>
                    </div>
                </div>
                <div className='m-footer-content-copyright'>
                    Copyright © 2022-2025. 7UCKY LTD
                </div>
            </div>
        </footer>
    );
};

export default FooterMobileView;
